import React from 'react'

export const GifGridItem = ( {id, title, url} ) => {

    //console.log ({id, title, url});

    return (
        <div className = "card animate__animated animate__bounce animate__fadeIn">
            <img src={ url } alt = { title }></img>
            <p> { title } </p>
            {/* { title } */}
        </div>
    )
}
