import React from 'react';
import ReactDOM from 'react-dom';
import { GitExpertApp } from './GitExpertApp';
import './index.css';

//const divRoot = document.querySelector('#root');

ReactDOM.render(
    <GitExpertApp  />,
  document.getElementById('root')
);

//ReactDOM.render(< GitExpertApp /> , divRoot); 